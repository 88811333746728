import { useContext } from "react";

// PROJECT IMPORT
import { MediaContext } from "./MediaProvider";

const useMedia = () => {
  const context = useContext(MediaContext);
  if (!context) throw new Error("You are accessing context outside of wrapper");
  return context;
};

export default useMedia;
