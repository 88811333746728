import React from "react";
import Tooltip from "./Tooltip";

interface AvatarProps {
  isTooltip?: any;
  name: string;
  className?: any;
}
const Avatar = (props: AvatarProps) => {
  const { name, className, isTooltip } = props;

  const names = name?.split(" ");
  const first = names?.[0]?.slice(0, 1)?.toUpperCase();

  return (
    <>
      {isTooltip ? (
        <Tooltip text={isTooltip && name}>
          <div
            className={`${className} rounded-full bg-white h-12 w-12 flex items-center justify-center font-bold text-xl`}
          >
            {first}
          </div>
        </Tooltip>
      ) : (
        <div
          className={`${className} rounded-full bg-white h-12 w-12 flex items-center justify-center font-bold text-xl`}
        >
          {first}
        </div>
      )}
    </>
  );
};

export default Avatar;
