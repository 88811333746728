import { useLocation } from "react-router";

// UI IMPORT
import { Icon, Select } from "../../ui";

// PROJECT IMPORT
import { useUser } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../utills/hooks";
import { toggleDrawer } from "../common/commonSlice";
import { logout } from "../../helperFunctions";
import { AiOutlineLogout } from "react-icons/ai";
import { useEffect, useState } from "react";

import "@progress/kendo-date-math/tz/all";
import { useMedia } from "../../components";
// import UploadApkModal from "./UploadApkModal";

const Header = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  // const [uploadApkModal, setUploadApkModal] = useState(false);
  const user = useUser();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { media } = useMedia();

  const parepareTitle = () => {
    const fLocation: any = `/${location?.pathname?.split("/")?.[1]}`;
    return location?.pathname?.split("/")?.[2] !== undefined &&
      fLocation === "/user"
      ? "Users Details"
      : user?.SiderBar?.find((item: any) => item?.route === fLocation)?.name;
  };

  return (
    <>
      <div className="mainNavbar webNav  fixed-top sticky -top-1 z-10 bg-[#fff] border-2 border-[#fff] ">
        <div className="navBar boxBetween flex justify-between items-center">
          <div className="navToggle title-nav flex">
            {media.width < 768 ? (
              <Icon
                className="me-2 w-6"
                name="MenuSidebar"
                onClick={() => {
                  dispatch(toggleDrawer());
                }}
              />
            ) : (
              ""
            )}
            {parepareTitle()}
          </div>
          {/* <Button type="button" onClick={() => setUploadApkModal(true)}>Update</Button> */}
          <div className="navIcons  boxBetween flex items-center">
            <div className="flex items-center md:justify-end">
              <div
                className="flex items-center justify-center bg-[#fff] rounded-full border-2 border-[#E8E9EE] h-[40px] w-[40px]"
                title="Logout"
                onClick={async () => {
                  try {
                    await logout(dispatch);
                    navigate("/");
                  } catch (err) {
                    console.error("Logout Error =-=>", err);
                  }
                }}
              >
                <AiOutlineLogout className="text-ev-Tertiary text-xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <UploadApkModal
        isOpen={uploadApkModal}
        onClose={() => setUploadApkModal(!uploadApkModal)}
      /> */}
    </>
  );
};

export default Header;
