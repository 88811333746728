import { api } from "../../utills/api";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getUser",
        body: params,
        method: "POST",
      }),
    }),
    AddUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUser",
        body: params,
        method: "POST",
      }),
    }),
    getBankAndMerchant: build.mutation<any, void>({
      query: () => ({
        url: "/getMerchantList",
        method: "POST",
      }),
    }),
    addBank: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addBank",
        body: params,
        method: "POST",
      }),
    }),
    sendNotification: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/sendNotification",
        body: params,
        method: "POST",
      }),
    }),
    resetPassword: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/resetPassword",
        body: params,
        method: "POST",
      }),
    }),
    resetDeviceID: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/resetDeviceId",
        body: params,
        method: "POST",
      }),
    }),
    deleteUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteUser",
        body: params,
        method: "POST",
      }),
    }),
    nickNameAddBank: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/nickNameAddBank",
        body: params,
        method: "POST",
      }),
    }),
    getUserDetails: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getUserDetails",
        body: params,
        method: "POST",
      }),
    }),
    addUpdateNickName: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addUpdateNickName",
        body: params,
        method: "POST",
      }),
    }),
    blockUserDevice: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/blockUserDevice",
        body: params,
        method: "POST",
      }),
    }),
    deleteNickUser: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deleteNickName",
        body: params,
        method: "POST",
      }),
    }),
    updateApk: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/updateApk",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUserMutation,
  useAddUserMutation,
  useGetBankAndMerchantMutation,
  useAddBankMutation,
  useSendNotificationMutation,
  useResetPasswordMutation,
  useResetDeviceIDMutation,
  useDeleteUserMutation,
  useNickNameAddBankMutation,
  useGetUserDetailsMutation,
  useAddUpdateNickNameMutation,
  useBlockUserDeviceMutation,
  useDeleteNickUserMutation,
  useUpdateApkMutation
} = userApi;
export const {
  endpoints: {
    getUser,
    AddUser,
    addBank,
    sendNotification,
    resetPassword,
    deleteUser,
    nickNameAddBank,
    getUserDetails,
    addUpdateNickName,
    blockUserDevice,
    deleteNickUser,
    updateApk
  },
} = userApi;
