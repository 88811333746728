import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Avatar, Button, ControlledInput, Table, TableRow } from "../../ui";
import { MdModeEditOutline } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdLockReset } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import PasswordModal from "./PasswordModal";

// PROJECT IMPORT

import {
  useDeleteUserMutation,
  useGetBankAndMerchantMutation,
  useGetUserMutation,
  useResetPasswordMutation,
} from "./userApi";
import { useUserData } from "./userSlice";
import { FILTER, USER_LOCAL } from "../../constant";

// THIRD - PARTY IMPORT
import UserModal from "./UserModal";
import SendNotificationButton from "./SendNotificationButton";
import { useForm } from "react-hook-form";
import {
  getLocalData,
  responseToaster,
  setLocalData,
  showToaster,
} from "../../helperFunctions";
import ControlledDropDown from "../../ui/forms/ControlledDropDown";
import { searchDataOptions } from "./mock";
import DeleteModal from "../common/DeleteModal";
import BankAsignModal from "./BankAsignModal";
import { useLocation, useNavigate } from "react-router";
import { FaRegEye } from "react-icons/fa6";
import Tooltip from "../../ui/elements/Tooltip";

const User = () => {
  const navigate = useNavigate();
  const route = useLocation()?.pathname;

  const userData = useUserData();
  const [filter, setFilter] = useState(getLocalData(USER_LOCAL, FILTER));
  const [getUser, { isLoading }] = useGetUserMutation();
  const [getBanksAndMerchant] = useGetBankAndMerchantMutation();

  const [asignBankModal, setAsignBankModal] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [tableData, setTableData] = useState<any>([]);
  const [currentData, setCurrentData] = useState({});
  const isInitialMount = useRef(true);
  const [isOpen, setIsOpen] = useState("");
  const [resetPassword, { isLoading: isLoadingResetPassword }] =
    useResetPasswordMutation();
  const [isResetID, setIsResetID] = useState("");
  const [isCopy, setIsCopy] = useState<any>();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [deleteUser, { isLoading: isUserLoading }] = useDeleteUserMutation();
  const [isOpenConfirmation, setisOpenConfirmation] = useState(false);

  const { handleSubmit, control, reset, getValues, watch } = useForm<any>({
    defaultValues: {
      filter: searchDataOptions?.[0]?.value,
      search: "",
    },
  });

  const onDeleteUser = async () => {
    try {
      const res = await deleteUser({ id: deleteID }).unwrap();
      setisOpenConfirmation(false);
      responseToaster(res);
      setFilter({ ...filter, isFilter: false });
    } catch (err) {}
  };

  const onResetPass = async (id: string, userName: string) => {
    try {
      const res = await resetPassword({ id }).unwrap();
      setIsPasswordModalOpen(true);
      setIsCopy({ ...res?.data, userName });
      responseToaster(res);
    } catch (err) {}
  };

  const toggleDropdown = (id: string) => {
    setIsOpen(id);
  };

  const columns = [
    {
      title: "#",
      Cell: (data: any) => (
        <>
          <FaRegEye />
        </>
      ),
    },
    {
      title: "Username",
      name: "user_name",
      sortableKey: "user_name",
      sortable: true,
      Cell: (item: any) => {
        return (
          <div className="flex items-center">
            <Avatar
              name={item?.user_name}
              className="!bg-[#f1f1f1] h-[30px] w-[30px] mr-2 !text-base text-ev-secondary"
            />
            <div className="text-ev-primary gap-2">{item?.user_name}</div>
          </div>
        );
      },
    },
    {
      title: "Merchant Name",
      name: "merchant_name",
    },
    {
      title: "Devices",
      name: "login_count",
      Cell: (item: any) => {
        return item?.login_count || 0;
      },
    },
    {
      title: "Registration Date",
      name: "created_at_ist",
      sortableKey: "created_at_ist",
      sortable: true,
    },
    {
      title: "Action",
      Cell: (data: any, e: any) => {
        return (
          <>
            <div className="flex gap-2 items-center">
              <Tooltip text="Edit">
                <div
                  className="cursor-pointer bg-chatlook-secondary rounded-md p-1"
                  onClick={(e) => {
                    setIsUserModalOpen(true);
                    setCurrentData(data);
                    toggleDropdown("");
                    e.stopPropagation();
                  }}
                >
                  <MdModeEditOutline size={20} />
                </div>
              </Tooltip>
              <Tooltip text="Reset Password">
                <div
                  className="cursor-pointer bg-chatlook-secondary rounded-md p-1"
                  onClick={(e) => {
                    toggleDropdown("");
                    onResetPass(data?.id, data?.user_name);
                    setIsResetID(data?.id);
                    e.stopPropagation();
                  }}
                >
                  <MdLockReset
                    size={20}
                    className={`${
                      !isPasswordModalOpen &&
                      isLoadingResetPassword &&
                      data?.id === isResetID
                        ? "animate-spin"
                        : ""
                    } `}
                  />
                </div>
              </Tooltip>

              <Tooltip text="Delete">
                <div
                  className="cursor-pointer bg-chatlook-secondary rounded-md p-1"
                  onClick={(e) => {
                    toggleDropdown("");
                    setisOpenConfirmation(true);
                    setDeleteID(data?.id);
                    e.stopPropagation();
                  }}
                >
                  <MdDelete size={20} />
                </div>
              </Tooltip>

              {data?.inActiveUser !== 0 ? (
                <SendNotificationButton
                  className="h-8"
                  classNameJustIcon="cursor-pointer bg-chatlook-secondary rounded-md p-1"
                  userName={data?.user_name ?? ""}
                  isBulk
                  justIcon
                  userId={data?.id}
                />
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const onGetData = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        filter: dropdown,
        search,
        ...rest
      } = filter;
      const payload: any = isFilter
        ? {
            page_no,
            limit,
            filter_data: {
              [search === "" ? "" : dropdown]: search,
              ...rest,
            },
          }
        : {
            page_no,
            limit,
          };

      const res = await getUser(payload).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(userData?.data?.length ? [...userData?.data] : []);
  }, [userData]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setLocalData(USER_LOCAL, {
      page_no: filter?.page_no,
      limit: filter?.limit,
    });
    // eslint-disable-next-line
  }, [filter?.page_no, filter?.limit]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({ ...filter, ...values, ...FILTER, isFilter: true });
  };

  return (
    <>
      <div>
        <div className="cardcss flex items-end justify-between">
          <form
            className="flex flex-wrap gap-2 items-end"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledDropDown
              width="135px"
              name="filter"
              label="Filter"
              options={searchDataOptions}
              control={control}
            />
            <ControlledInput
              placeholder={`Enter ${
                searchDataOptions.find(
                  (item: any) => item?.value === watch("filter")
                )?.label
              }`}
              name="search"
              label="Search"
              autoComplete="off"
              control={control}
            />

            <Button type="submit" className=" !text-sm">
              Apply
            </Button>
            <Button
              variant="Transaparent"
              className="!text-sm"
              onClick={() => {
                reset();
                setFilter({ ...FILTER });
              }}
            >
              Clear
            </Button>
          </form>
          <div className="flex flex-wrap gap-2 items-start justify-end px-1">
            <Button
              className="!h-9 !text-sm"
              onClick={async () => {
                setIsUserModalOpen(true);
                await getBanksAndMerchant().unwrap();
              }}
            >
              Add User
            </Button>
          </div>
        </div>

        <div className="mt-2">
          <div className="App mt-3">
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData}
              setData={setTableData}
              count={userData?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      className="cursor-pointer"
                      columns={columns}
                      isExpendable={false}
                      item={item}
                      onClick={(item: any) => {
                        item?.login_count
                          ? navigate(`${route}/${item?.id}`)
                          : showToaster("Login Device Not Found for This User", "Error");
                      }}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <BankAsignModal
        data={currentData}
        isOpen={asignBankModal}
        onClose={() => {
          setAsignBankModal(false);
          setCurrentData({});
        }}
        getUserData={onGetData}
      />
      <UserModal
        data={currentData}
        isOpen={isUserModalOpen}
        onClose={() => {
          setIsUserModalOpen(false);
          setCurrentData({});
        }}
        getUserData={onGetData}
      />
      <PasswordModal
        isOpen={!!isPasswordModalOpen && !isLoadingResetPassword}
        onHide={() => {
          setIsPasswordModalOpen(false);
          setIsCopy({});
        }}
        isCopy={isCopy}
      />
      <DeleteModal
        isLoading={isUserLoading}
        isOpen={!!isOpenConfirmation}
        text="Are you sure you want to delete this user?"
        onHide={() => {
          setisOpenConfirmation(false);
        }}
        onDoneClick={() => {
          onDeleteUser();
        }}
      />
    </>
  );
};

export default User;
