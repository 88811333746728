import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// UI IMPORT
import { Loader } from "./ui";

// PROJECT IMPORT
import AllRoutes from "./components/routes/AllRoutes";
import { useUser } from "./features/auth/authSlice";
import { prepareBlobUrl, setStyles } from "./helperFunctions";
import { useLogout } from "./hooks";
import "./App.css";

// THIRD - PARTY IMPORT
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

function App() {
  const user = useUser();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const color: any = process.env.REACT_APP_COLOR;

  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [user]);

  useLayoutEffect(() => {
    if (color) {
      setIsLoading(true);
      setStyles("--chatlook-primary--", color);
      setStyles("--ev-primary--", color);
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const bodyStyle = process.env.REACT_APP_BODY_STYLE || "white";
  const logo = process.env.REACT_APP_FAVICON;

  // Applying body color
  document.body.style.fontFamily = bodyStyle;

  useEffect(() => {
    const fontEnv = process.env.REACT_APP_FONT_ENV;
    if (fontEnv === "Roboto") {
      document.body.style.fontFamily = "Roboto, sans-serif";
    } else if (fontEnv === "Mulish") {
      document.body.style.fontFamily = "Mulsih, sans-serif";
    }
  }, [bodyStyle]);

  useLogout();

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <link rel="icon" type="image/svg+xml" href={prepareBlobUrl(logo)} />
      </Helmet>
      <AllRoutes />
      <Toaster />
    </div>
  );
}

export default App;
