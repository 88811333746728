import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { authApi } from "./authApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface AuthState {
  user: any;
}

const initialState: AuthState = {
  user: {
    SiderBar: [
      {
        name: "SMS Log",
        route: "/sms_log",
        icon_class: "SmsLogIcon",
      },
      {
        name: "Users",
        route: "/user",
        icon_class: "UserIcon",
      },
      // {
      //   name: "Bank",
      //   route: "/bank",
      //   icon_class: "BankIcon",
      // },
    ],
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.user = initialState?.user;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = { ...payload, ...initialState?.user };
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default authSlice.reducer;
export const { clearAuth } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};
