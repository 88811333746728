import { api } from "../../utills/api";
import { LoginFormTypes } from "./types";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<any, LoginFormTypes>({
      query: (params) => ({
        url: "/adminLogin",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
export const {
  endpoints: { login },
} = authApi;
