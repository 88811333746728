import { CSSProperties, PropsWithChildren, useState, Fragment } from "react";
import TableCell from "./TableCell";

interface TableRowProps extends PropsWithChildren<{}> {
  className?: string;
  style?: CSSProperties;
  isExpendable?: boolean;
  item?: any;
  columns: any[];
  onEyeClick?: (item?: any) => void;
  onClick?: (e?: any) => any;
}
const TableRow = (props: TableRowProps) => {
  const {
    className = "",
    style = {},
    item = {},
    isExpendable = true,
    columns = [],
    children,
    onEyeClick,
    onClick,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr
        className={`${className} table-row tablecell-hoverx `}
        style={{ ...style }}
        onClick={(e) => {
          onClick?.(item);
          if (isExpendable) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {isExpendable ? (
          <TableCell
            className="cursor-pointer"
            onClick={(e) => {
              // e.stopPropagation();
              onEyeClick?.(item);
            }}
          >
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99995 10.8866C6.40661 10.8866 5.11328 9.59328 5.11328 7.99995C5.11328 6.40661 6.40661 5.11328 7.99995 5.11328C9.59328 5.11328 10.8866 6.40661 10.8866 7.99995C10.8866 9.59328 9.59328 10.8866 7.99995 10.8866ZM7.99995 6.11328C6.95995 6.11328 6.11328 6.95995 6.11328 7.99995C6.11328 9.03995 6.95995 9.88661 7.99995 9.88661C9.03995 9.88661 9.88661 9.03995 9.88661 7.99995C9.88661 6.95995 9.03995 6.11328 7.99995 6.11328Z"
                  fill={`${isOpen ? "black" : "black"}`}
                />
                <path
                  d="M8.00022 14.0134C5.49355 14.0134 3.12688 12.5467 1.50021 10C0.793548 8.90003 0.793548 7.10669 1.50021 6.00003C3.13355 3.45336 5.50022 1.98669 8.00022 1.98669C10.5002 1.98669 12.8669 3.45336 14.4935 6.00003C15.2002 7.10003 15.2002 8.89336 14.4935 10C12.8669 12.5467 10.5002 14.0134 8.00022 14.0134ZM8.00022 2.98669C5.84688 2.98669 3.78688 4.28003 2.34688 6.54003C1.84688 7.32003 1.84688 8.68003 2.34688 9.46003C3.78688 11.72 5.84688 13.0134 8.00022 13.0134C10.1535 13.0134 12.2135 11.72 13.6535 9.46003C14.1535 8.68003 14.1535 7.32003 13.6535 6.54003C12.2135 4.28003 10.1535 2.98669 8.00022 2.98669Z"
                  fill={`${isOpen ? "black" : "black"}`}
                />
              </svg>
            </div>
          </TableCell>
        ) : null}
        {columns?.map((column, index) => {
          const { Cell, name, type } = column;
          return (
            <Fragment key={index}>
              <TableCell type={type}>
                {Cell ? <Cell {...item} /> : item?.[name] || "-"}
              </TableCell>
            </Fragment>
          );
        })}
      </tr>
      {isOpen ? (
        <tr className="open-tabledata rounded-md">
          <TableCell colSpan={+columns?.length + 1} className="open-padding">
            <div className="d-flex">{children}</div>
          </TableCell>
        </tr>
      ) : null}
    </>
  );
};

export default TableRow;
