import { Navigate, Route, Routes } from "react-router-dom";
import { Fragment } from "react";

// PROJECT IMPORT
import { User, Header, SideBar, SMS } from "../../features";
import { useIsDrawerOpen } from "../../features/common/commonSlice";
import { useMedia } from "../wrappers";
import { useUser } from "../../features/auth/authSlice";
import UserDetails from "../../features/user/UserDetails";
// import Bank from "../../features/bank/Bank";

const UserRoutes = () => {
  const { media } = useMedia();
  const user = useUser();

  const isDrawerOpen = useIsDrawerOpen();

  const Components: any = {
    "SMS Log": SMS,
    Users: User,
    // Bank: Bank,
  };

  return (
    <div className=" h-screen sm:ml-0 sm:my-0 sm:h-[calc(100vh-0px)] overflow-y-scroll  main relative">
      <SideBar />
      <div
        className={`${
          isDrawerOpen
            ? media.width < 768
              ? "pl-[0px]"
              : "pl-[220px]"
            : media.width < 768
            ? "pl-[0px]"
            : "pl-[74px]"
        } 
        ${media.width < 768 ? "pr-[0px] pl-[0px]" : "pr-[0px]"} 
         mainAdmin`}
        //  className={`${
        //   isDrawerOpen && media.width < 768
        //     ? "pl-[10px]"
        //     : isDrawerOpen && media.width > 768
        //     ? "pl-[220px]"
        //     : "pl-[74px]"
        // }
        // ${media.width < 768 && !isDrawerOpen ? "pl-[10px]" : ""}
        // ${media.width < 768 ? "pr-[10px]" : "pr-[0px]"}
        //  mainAdmin`}
      >
        <Header />
        <div className="p-2">
          <Routes>
            {user?.SiderBar?.map((item: any, index: number) => {
              const Element = Components?.[item?.name];
              if (!Element) {
                return <></>;
              }
              return (
                <Fragment key={index}>
                  {index === 0 ? (
                    <Route path="/" element={<Navigate to={item?.route} />} />
                  ) : null}
                  <Route path={item?.route} element={<Element />} />
                </Fragment>
              );
            })}

            {user?.SiderBar?.find((item: any) => item?.route === "/user") ? (
              <>
                <Route path="/user/:id" element={<UserDetails />} />
              </>
            ) : null}

            <Route
              path="*"
              element={<Navigate to={user?.SiderBar?.[0]?.route} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserRoutes;
