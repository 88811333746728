import React from "react";
import { Button } from "../../ui";
import { useSendNotificationMutation } from "./userApi";
import { responseToaster } from "../../helperFunctions";
import { GrSend } from "react-icons/gr";
import Tooltip from "../../ui/elements/Tooltip";

const SendNotificationButton = ({
  isNotIcon,
  nickNameId,
  className,
  userId,
  justIcon,
  classNameJustIcon,
  isBulk = false,
}: any) => {
  const [sendNotificationM, { isLoading: isLoadingSendNotification }] =
    useSendNotificationMutation();
  const sendNotification = async () => {
    try {
      const recipientId = isBulk
        ? { userId: userId }
        : { nickNameId: nickNameId };
      const res = await sendNotificationM(recipientId).unwrap();
      responseToaster(res);
    } catch (err) {}
  };

  return (
    <>
      {justIcon ? (
        <Tooltip text="Send Notification">
          <div
            className={classNameJustIcon}
            onClick={(e) => e.stopPropagation()}
          >
            <GrSend onClick={sendNotification} size={20} />
          </div>
        </Tooltip>
      ) : (
        <Button
          className={className}
          isLoading={isLoadingSendNotification}
          onClick={sendNotification}
        >
          {isNotIcon ? "Bulk Notification" : <GrSend />}
        </Button>
      )}
    </>
  );
};

export default SendNotificationButton;
