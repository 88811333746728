import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import { Button, Input, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { useGetSMSMutation, useLazyGetUserListQuery } from "./smsApi";
import { useSMSData, useUserList } from "./smsSlice";
import { FILTER, SMS_LOCAL } from "../../constant";
import {
  getLocalData,
  responseToaster,
  setLocalData,
} from "../../helperFunctions";

// THIRD - PARTY IMPORT
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";
import { statusOptions } from "../common/mock";
import ControlledDropDown from "../../ui/forms/ControlledDropDown";
import { useNavigate } from "react-router-dom";
import { FaArrowsRotate } from "react-icons/fa6";

const SMS = () => {
  const data = useSMSData();

  const [getserListQuery] = useLazyGetUserListQuery();
  const userList = useUserList();
  const isInitialMount = useRef(true);
  const [tableData, setTableData] = useState<any>([]);
  const [getSMS] = useGetSMSMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [value] = useDebounce(searchTerm, 1000);
  const [filter, setFilter] = useState<any>(getLocalData(SMS_LOCAL, FILTER));
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { handleSubmit, control, reset, getValues, watch } = useForm<any>({
    defaultValues: {
      userId: statusOptions?.[0]?.value,
      nickNameId: statusOptions?.[0]?.value,
    },
  });
  const [nickNameEdit, setNickNameEdit] = useState({});
  const columns = [
    {
      title: "Username",
      name: "user_name",
      Cell: (data: any) => <>{data?.user_details?.user_name}</>,
    },
    {
      title: "Nickname",
      name: "nick_name",
      Cell: (data: any) => (
        <>
          <div>
            {data?.nick_name_details?.length
              ? data?.nick_name_details?.map((val: any, index: any) => {
                  return (
                    <p
                      className="text-ev-primary cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        {
                          !val?.nick_name &&
                            navigate(`/user/${data?.user_id}`, {
                              state: {
                                nickID: val?.id,
                                // nickName: val?.nick_name,
                              },
                            });
                        }
                      }}
                    >
                      {val?.nick_name || "Set Nickname"}
                    </p>
                  );
                })
              : "-"}
          </div>
        </>
      ),
    },
    {
      title: "Sender ID",
      name: "sender_id",
    },
    {
      title: "Text",
      name: "text",
      type: "ellipsis",
    },
    {
      title: "Actual Date & Time",
      name: "sms_date_time",
      sortableKey: "sms_date_time",
      sortable: true,
    },
    {
      title: "Sync Date & Time",
      name: "created_at_ist",
      sortableKey: "created_at_ist",
      sortable: true,
    },
  ];

  const prepareUserOptions = () => {
    const options: any = (userList?.data ?? [])?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.user_name,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  const prepareNickUserOptions = () => {
    const options: any = (userList?.NickNameList ?? [])
      ?.filter((item: any) => !!item?.nick_name)
      ?.map((val: any) => {
        return {
          value: val?.id,
          label: val?.nick_name,
        };
      });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  const onGetData = async (loading = false, isToast = true) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const { isFilter, page_no, limit, ...rest } = filter;
      const payload: any = {
        filter_data: {
          searchData: value,
          ...rest,
        },
        page_no,
        limit,
      };
      const res = await getSMS(payload).unwrap();
      if (isFilter && isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetData(true);
    // eslint-disable-next-line
  }, [filter, value]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setLocalData(SMS_LOCAL, {
      page_no: filter?.page_no,
      limit: filter?.limit,
    });
    // eslint-disable-next-line
  }, [filter?.page_no, filter?.limit]);

  useEffect(() => {
    setTableData(data?.data?.length ? [...data?.data] : []);
  }, [data]);

  useEffect(() => {
    getserListQuery();
  }, []);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        onGetData(true, false);
        getserListQuery();
      }
    }, 10000);
    return () => clearInterval(IntervalClick);
  }, [refresh, filter, value]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({ ...filter, ...values, ...FILTER, isFilter: true });
  };

  return (
    <>
      <div>
        <div className={`mt-2 ${isLoading && refresh ? "opacity-25" : ""}`}>
          <div className="cardcss mb-3 flex items-center justify-between  pb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ControlledDropDown
                width="135px"
                name="userId"
                label="Username"
                options={prepareUserOptions()}
                control={control}
              />
              <ControlledDropDown
                width="135px"
                name="nickNameId"
                label="Nickname"
                options={prepareNickUserOptions()}
                control={control}
              />
              <Button type="submit" className="!text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER });
                }}
              >
                Clear
              </Button>
            </form>
            <div className="pt-5 flex flex-wrap gap-2 justify-end">
              <Button
                type="button"
                className={`${refresh ? "refreshBtn" : "filterbtn"} gap-2`}
                color={refresh ? "primary" : ""}
                onClick={() => setRefresh(!refresh)}
              >
                <FaArrowsRotate
                  className={`${refresh ? "rotate-animation" : ""}`}
                />
                Auto Refresh
              </Button>
              <Input
                name="searchData"
                className="w-100"
                rightIcon={searchTerm !== "" ? "Closeicon" : "SearchIcon"}
                righticonClick={() => setSearchTerm("")}
                placeholder="Enter text"
                value={searchTerm}
                type="text"
                autoComplete="off"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="App mt-3">
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading && !refresh}
              data={tableData}
              count={data?.total_item || 0}
              pagination={filter}
              setData={setTableData}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMS;
