// PROJECT IMPORT
import { clearAuth } from "./features/auth/authSlice";
import { clearCommon } from "./features/common/commonSlice";
import { clearUser } from "./features/user/userSlice";
import { clearSMS } from "./features/sms/smsSlice";

// THIRD - PARTY IMPORT
import { toast } from "react-hot-toast";
import { FiAlertTriangle } from "react-icons/fi";

const root = window.document.documentElement;

export const kFormatter = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const logout = async (dispatch) => {
  dispatch(clearAuth());
  dispatch(clearCommon());
  dispatch(clearUser());
  dispatch(clearSMS());
};

export const downloadFile = (url, type, name) => {
  let element = document.createElement("a");
  let file = new Blob([url], { type: `${type}/*` });
  element.href = URL.createObjectURL(file);
  element.download = name;
  element.click();
};

export const showToaster = (message, type = "Success") => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    case "Warning":
      toast.custom(
        (t) => (
          <div className="custom-toast">
            <FiAlertTriangle size={24} />
            <span>{message}</span>
          </div>
        ),
        {
          position: "top-right",
        }
      );
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const changeFavicon = (newFaviconUrl) => {
  const favicon = window.document.querySelector('link[rel="icon"]') || {};
  favicon.href = newFaviconUrl;
};

export const setStyles = (varr, color) => {
  root?.style.setProperty(varr, color);
};

export const dateFormatter = (d, type = "start") => {
  if (!d) {
    return null;
  }
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}${
    type === "end"
      ? " 23:59:59"
      : type === "start"
      ? " 00:00:00"
      : type === "Default"
      ? ""
      : " " + hour + ":" + minute + ":" + seconds
  }`;
};

export const seperator = (x, rupee = true) => {
  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const val = f.format(Math.abs(+x) || 0)?.toString();

  const newValues = val?.split(".");
  let newVal = val;
  if (newValues?.length === 2 && newValues?.at(-1) === "00") {
    newVal = newValues?.[0];
  }
  if (!rupee) {
    newVal = newVal?.slice(1);
  }

  return x?.toString()?.slice(0, 1) === "-" ? `-${newVal}` : newVal;
};

export const prepareMaxDate = (startDate, endDate, maxDay = 30) => {
  if (!startDate || (startDate && endDate)) {
    return new Date();
  }
  const d = new Date(startDate);
  d.setDate(d.getDate() + maxDay);
  return d > new Date() ? new Date() : d;
};
export const prepareBlobUrl = (svgString, type = "image/svg+xml") => {
  const blob = new Blob([svgString], { type });
  return URL.createObjectURL(blob);
};

export const responseToaster = (res) => {
  if (res) {
    showToaster(
      res.message,
      res?.warning ? "Warning" : res?.status ? "Success" : "Error"
    );
  } else {
    showToaster("Something Went Wrong!", "Error");
  }
};

export const setLocalData = (key, val, defaultValue) => {
  localStorage.setItem(key, JSON.stringify(val ?? defaultValue));
};

export const getLocalData = (key, defaultValue = {}) => {
  return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
};

export const sortByKey = (data, name, sort = "Asce") => {
  const newData = data?.length ? [...data] : [];
  const isDesc = sort === "Desc";
  const keys = name.split(".");

  const getValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc?.[key], obj);
  };

  const compare = (a, b) => {
    const s = getValue(a, keys);
    const l = getValue(b, keys);
    if (s?.toString()?.toLowerCase() < l?.toString()?.toLowerCase()) {
      return isDesc ? 1 : -1;
    }
    if (s?.toString()?.toLowerCase() > l?.toString()?.toLowerCase()) {
      return isDesc ? -1 : 1;
    }
    return 0;
  };
  return newData.sort(compare);
};

export const sortByDate = (data, name, sort = "Asce") => {
  const sortedAscending = data
    .slice()
    .sort((a, b) => new Date(a?.[name]) - new Date(b?.[name]));

  const sortedDescending = data
    .slice()
    .sort((a, b) => new Date(b?.[name]) - new Date(a?.[name]));
  return sort === "Asce" ? sortedAscending : sortedDescending;
};

export function getAcronym(str) {
  return str
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase();
}
