import { useAddUserMutation, useGetBankAndMerchantMutation } from "./userApi";
import { Button, ControlledInput, Icon, Input, Loader } from "../../ui";
import { getAcronym, showToaster } from "../../helperFunctions";
import CopyToClipboard from "react-copy-to-clipboard";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMerchantData } from "./userSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import ControlledDropDown from "../../ui/forms/ControlledDropDown";
import { getBankListOptions } from "./mock";
import DropDown from "../../ui/forms/DropDown";

const UserModal = (props: any) => {
  const { isOpen, data, onClose, getUserData } = props;
  const [isCopy, setIsCopy] = useState<any>();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [bankFromIFSC, setbankFromIFSC] = useState<any>();
  const [bankFromIFSCDynamic, setbankFromIFSCDynamic] = useState<any>([""]);
  const [bankOptions, setBankOptions] = useState<any>();
  const [AddUser] = useAddUserMutation();
  const [getBanksAndMerchant] = useGetBankAndMerchantMutation();
  const [dynamicFields, setDynamicFields] = useState<any>([]);
  const [dynamicFieldsErrors, setDynamicFieldsErrors] = useState<any>([]);
  const [showMerchantInput, setShowMerchantInput] = useState(false);
  const merchantList = useMerchantData();
  const validateIFSCAsync = async (value: any) => {
    try {
      if (value) {
        const response = await axios.get(`https://ifsc.razorpay.com/${value}`);
        setbankFromIFSC(`${response?.data?.BANK} - ${response?.data?.BRANCH}`);
        if (
          !bankOptions?.find(
            (item: any) => item?.value === response?.data?.BANK
          )
        ) {
          setBankOptions([
            ...bankOptions,
            {
              label: `${getAcronym(response?.data?.BANK)} - ${
                response?.data?.BANK
              }`,
              value: response?.data?.BANK,
            },
          ]);
        }
        return !!response.data;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const validateIFSCAsync1 = async (value: any, index: number) => {
    try {
      if (value) {
        const response = await axios.get(`https://ifsc.razorpay.com/${value}`);
        const newBankFromIFSCDynamic = [...bankFromIFSCDynamic];
        newBankFromIFSCDynamic[
          index
        ] = `${response?.data?.BANK} - ${response?.data?.BRANCH}`;
        setbankFromIFSCDynamic(newBankFromIFSCDynamic);
        if (
          !bankOptions?.find(
            (item: any) => item?.value === response?.data?.BANK
          )
        ) {
          setBankOptions([
            ...bankOptions,
            {
              label: `${getAcronym(response?.data?.BANK)} - ${
                response?.data?.BANK
              }`,
              value: response?.data?.BANK,
            },
          ]);
        }
        return !!response.data;
      }
      return false;
    } catch (error) {
      setDynamicFieldsErrors((prevFields: any) => {
        const newFields = [...prevFields];
        let errorMessage = "";
        errorMessage = "Invalid IFSC code";
        newFields[index]["ifsc_code"] = errorMessage;
        return newFields;
      });
      return false;
    }
  };

  const schema = yup.object({
    userName: yup.string().required("Please enter user name"),
    merchantName: yup.string().required("Please select merchant name"),
  });
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({ resolver: yupResolver<any>(schema), defaultValues: {} });

  useEffect(() => {
    if (Object.keys(data)?.length) {
      const modifiedTemp = {
        userName: data?.user_name,
        merchantName: data?.merchant_name,
        userId: data?.id,
        bankData: data?.sms_user_bank_details?.map(
          (bank: {
            id: any;
            ifsc_code: any;
            bank_name: any;
            account_number: any;
            upi_id: any;
          }) => ({
            id: bank?.id,
            ifsc_code: bank?.ifsc_code,
            bank_name: bank?.bank_name,
            account_number: bank?.account_number,
            upi_id: bank?.upi_id,
          })
        ),
      };

      setValue("userName", modifiedTemp?.userName);
      setValue("merchantName", modifiedTemp?.merchantName);
      setDynamicFields(modifiedTemp?.bankData);
      setDynamicFieldsErrors(
        data?.sms_user_bank_details?.map((bank: any) => ({
          ifsc_code: "",
          bank_name: "",
          account_number: "",
          upi_id: "",
        }))
      );
      getBanksAndMerchant();
    } else {
      // Add USER
      handleAddFields();
    }
  }, [data]);

  useEffect(() => {
    getBanksAndMerchant();
  }, []);

  const findDuplicateUPI = () => {
    const uniqueUPIs: Set<string> = new Set();
    const duplicateUPIs: string[] = [];

    dynamicFields.forEach((item: any) => {
      if (item.upi_id && uniqueUPIs.has(item.upi_id)) {
        duplicateUPIs.push(item.upi_id);
      } else {
        uniqueUPIs.add(item.upi_id);
      }
    });

    return duplicateUPIs;
  };

  const findDuplicateInfo = () => {
    const uniqueInfo: Map<string, Set<string>> = new Map();
    const duplicateInfo: string[] = [];

    dynamicFields.forEach((item: any) => {
      const key = `${item.ifsc_code}-${item.account_number}`;
      if (uniqueInfo.has(key)) {
        duplicateInfo.push(key);
      } else {
        uniqueInfo.set(key, new Set([item.upi_id]));
      }
    });

    return duplicateInfo;
  };

  const handleButtonClick = async () => {
    try {
      const newErrors = await Promise.all(
        dynamicFields?.map(async (item: any, index: number) => {
          const errors: any = {};
          if (item?.ifsc_code.trim() === "") {
            errors.ifsc_code = "Please enter IFSC code.";
          }
          if (item?.bank_name.trim() === "") {
            errors.bank_name = "Please select Bank.";
          }
          if (item?.account_number.trim() === "") {
            errors.account_number = "Please enter Bank account number.";
          }
          const isValidUpi = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(
            item?.upi_id.trim()
          );
          if (!isValidUpi) {
            errors.upi_id = "Please enter UPI ID.";
          }

          try {
            await axios.get(`https://ifsc.razorpay.com/${item?.ifsc_code}`);
          } catch (error) {
            errors.ifsc_code = "Invalid IFSC code";
          }

          setDynamicFieldsErrors((prevErrors: any) => {
            const newErrors = [...prevErrors];
            newErrors[index] = errors;
            return newErrors;
          });
          return errors;
        })
      );
      // Check if any field has error
      const hasError = newErrors?.some(
        (error: any) => Object.keys(error)?.length !== 0
      );

      if (hasError) {
        return;
      }

      if (!customValidation()) {
        return;
      }

      setisLoading(true);
      const values = getValues();
      const { IfscCode, bankAcNo, bankName, merchantName, upiId, userName } =
        values;
      let payload: any = {
        bankData: dynamicFields,
        IfscCode,
        bankAcNo,
        bankName,
        merchantName,
        upiId,
        userName,
      };
      const res: any = await AddUser({
        ...(data && data?.id && { userId: data?.id }),
        ...payload,
      });

      if (res?.data?.status && !data?.id) {
        showToaster(res?.data?.message);
        setIsCopy(res?.data?.data);
        getUserData();
        setShowMerchantInput(false);
      }
      if (!!data?.id) {
        showToaster(res?.data?.message);
        getUserData();
        onClose();
        setShowMerchantInput(false);
      }
      setisLoading(false);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const prepareBankOptions = () => {
    const options =
      (getBankListOptions ?? [])
        .map((item: any) => ({
          label: `${item?.label} - ${item?.value}`,
          value: item?.value,
        }))
        .sort((a: { label: string }, b: { label: any }) =>
          a.label.localeCompare(b.label)
        ) || [];
    setBankOptions([...options]);
    return [...options];
  };

  useEffect(() => {
    prepareBankOptions();
  }, []);

  const prepareMerchantOptions = () => {
    const options =
      (merchantList ?? [])
        ?.slice()
        ?.sort((a: any, b: any) =>
          a?.merchant_name?.localeCompare(b?.merchant_name)
        )
        ?.map((val: any) => ({
          value: val?.merchant_name,
          label: val?.merchant_name,
        }))
        .sort((a: { label: string }, b: { label: any }) =>
          a.label.localeCompare(b.label)
        ) || [];
    return [...options];
  };

  const handleAddFields = () => {
    setDynamicFields((prevFields: any) => [
      ...prevFields,
      {
        ifsc_code: "",
        bank_name: "",
        account_number: "",
        upi_id: "",
      },
    ]);
    setDynamicFieldsErrors((prevFields: any) => [
      ...prevFields,
      {
        ifsc_code: "",
        bank_name: "",
        account_number: "",
        upi_id: "",
      },
    ]);
  };

  const validateFields = () => {
    dynamicFields.forEach((item: any, index: any) => {
      Object.entries(item ?? {})
        ?.filter(([key, value]: any) => key !== "id")
        ?.forEach(([key, value]: any) => {
          setDynamicFieldsErrors((prevFields: any) => {
            const newFields = [...prevFields];

            const trimmedValue = value?.trim();

            let errorMessage = "";

            switch (key) {
              case "ifsc_code":
                errorMessage = trimmedValue.length
                  ? ""
                  : "Please enter IFSC code.";
                break;
              case "bank_name":
                errorMessage = trimmedValue.length ? "" : "Please select Bank.";
                break;
              case "account_number":
                errorMessage = trimmedValue.length
                  ? ""
                  : "Please enter Bank account number.";
                break;
              case "upi_id":
                const isValidUpi = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(
                  trimmedValue
                );
                errorMessage = isValidUpi ? "" : "Please enter UPI ID";
                break;
              default:
                errorMessage = "";
            }

            if (key === "ifsc_code") {
              validateIFSCAsync1(trimmedValue, index);
            }

            newFields[index][key] = errorMessage;

            return newFields;
          });
        });
    });
  };

  const customValidation = () => {
    // UPI ID could not be same
    const duplicateUPIs = findDuplicateUPI();
    if (duplicateUPIs?.length) {
      showToaster("There are duplicate UPI Ids", "Error");
      return;
    }

    // same bank account number should not available when ifsc are same
    const duplicateInfo = findDuplicateInfo();
    if (duplicateInfo?.length) {
      showToaster("There are duplicate accounts", "Error");
      return;
    }

    return true;
  };

  const handleChange = (index: number, key: string, value: any) => {
    setDynamicFields((prevFields: any) => {
      const newFields = [...prevFields];
      newFields[index][key] = value;
      return newFields;
    });

    setDynamicFieldsErrors((prevFields: any) => {
      const newFields = [...prevFields];

      const trimmedValue = value.trim();

      let errorMessage = "";

      switch (key) {
        case "ifsc_code":
          errorMessage = trimmedValue.length ? "" : "Please enter IFSC code.";
          break;
        case "bank_name":
          errorMessage = trimmedValue.length ? "" : "Please select Bank.";
          break;
        case "account_number":
          errorMessage = trimmedValue.length
            ? ""
            : "Please enter Bank account number.";
          break;
        case "upi_id":
          const isValidUpi = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(
            trimmedValue
          );
          errorMessage = isValidUpi ? "" : "Please enter UPI ID";
          break;
        default:
          errorMessage = "";
      }

      newFields[index][key] = errorMessage;

      return newFields;
    });
  };

  const handleDeleteBank = (index: number) => {
    setDynamicFields((prevFields: any) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
    setDynamicFieldsErrors((prevFields: any) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
    setbankFromIFSCDynamic((prevFields: any) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full  bg-gray-900 opacity-50"
        onClick={() => {
          reset();
          setIsCopy(null);
          onClose();
          setDynamicFieldsErrors([]);
          setDynamicFields([]);
          setbankFromIFSCDynamic([]);
          setShowMerchantInput(false);
        }}
      />

      <div className="modal-container bg-white w-7/12 md:w-8/12 sm:w-8/12 xs:w-10/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div
          className="modal-content px-5 pb-5 text-left"
          style={{ maxHeight: "500px" }}
        >
          <div className="flex justify-between items-center sticky top-0 pt-5 pb-2 z-10 bg-chatlook-white">
            <p className="text-xl font-bold text-ev-Tertiary">User Details</p>
            <div className="modal-close cursor-pointer z-50  flex items-center gap-2">
              <Icon
                name="CrossIcon"
                alt="Logo"
                onClick={() => {
                  reset();
                  setIsCopy(null);
                  onClose();
                  setDynamicFieldsErrors([]);
                  setDynamicFields([]);
                  setbankFromIFSCDynamic([]);
                  setShowMerchantInput(false);
                }}
              />
            </div>
          </div>
          {!!!isCopy ? (
            <div>
              <form onSubmit={handleSubmit(handleButtonClick)}>
                <div className="flex items-start pt-2">
                  <ControlledInput
                    className="me-2 w-full"
                    name="userName"
                    placeholder="Enter User Name"
                    label="User Name"
                    control={control}
                    onChange={(e) => {
                      if (/^\S*$/.test(e.target.value)) {
                        setValue("userName", e.target.value);
                      }
                    }}
                    errors={errors}
                    disabled={!!data?.id}
                  />
                  <div className="me-2 w-full flex">
                    {!showMerchantInput ? (
                      <ControlledDropDown
                        className="me-2 w-full"
                        name="merchantName"
                        label="Merchant Name"
                        options={prepareMerchantOptions()}
                        control={control}
                        errors={errors}
                      />
                    ) : (
                      <ControlledInput
                        className="me-2 w-full"
                        name="merchantName"
                        placeholder="Enter New Merchant Name"
                        label="Enter New Merchant Name"
                        control={control}
                        errors={errors}
                      />
                    )}
                    {!showMerchantInput ? (
                      <Icon
                        name="PlusIcon"
                        width={16}
                        height={16}
                        className="mr-2 mt-4"
                        onClick={() => {
                          setShowMerchantInput(true);
                          resetField("merchantName");
                        }}
                      />
                    ) : (
                      <Icon
                        name="Closeicon"
                        alt="Logo"
                        className="mr-2 mt-4"
                        onClick={() => {
                          setShowMerchantInput(false);
                          resetField("merchantName");
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="pt-2">
                  <p className="text-xl font-bold text-ev-Tertiary pt-2">
                    Bank Details
                  </p>
                  <div className="w-full grid grid-cols-9 gap-2">
                    {dynamicFields?.map((item: any, i: number, arr: any[]) => {
                      return (
                        <>
                          <Fragment key={i}>
                            {Object.entries?.(item).map(
                              ([k, v]: any, index, array: any[]) => {
                                return k === "ifsc_code" ||
                                  k === "bank_name" ||
                                  k === "account_number" ||
                                  k === "upi_id" ? (
                                  <>
                                    <Fragment key={k}>
                                      {k === "ifsc_code" ? (
                                        <div className="pt-2 relative !col-span-2">
                                          <Input
                                            inputClassName="pr-7"
                                            className="me-2 w-full"
                                            name={`IfscCode-${i}`}
                                            placeholder="Enter IFSC code"
                                            label="IFSC"
                                            value={v}
                                            onChange={(e: any) => {
                                              handleChange(
                                                i,
                                                k,
                                                e.target.value.toUpperCase()
                                              );
                                              const newBankFromIFSCDynamic = [
                                                ...bankFromIFSCDynamic,
                                              ];
                                              newBankFromIFSCDynamic[i] = "";
                                              setbankFromIFSCDynamic(
                                                newBankFromIFSCDynamic
                                              );
                                            }}
                                            err={
                                              dynamicFieldsErrors[i]?.[
                                                "ifsc_code"
                                              ]
                                            }
                                          />
                                          <div
                                            className="absolute right-3 top-[41px]"
                                            onClick={() => {
                                              validateIFSCAsync1(v, i);
                                            }}
                                          >
                                            <Icon name="CheckRoundedIcon" />
                                          </div>
                                          <span className="text-[10px] text-ev-primary">
                                            {!!bankFromIFSCDynamic?.[i]?.length
                                              ? bankFromIFSCDynamic?.[i]
                                              : null}
                                          </span>
                                        </div>
                                      ) : null}
                                      {k === "bank_name" ? (
                                        <div className="pt-2 col-span-2">
                                          <DropDown
                                            className="me-2 w-full"
                                            name={`bankName-${i}`}
                                            label="Bank Name"
                                            options={bankOptions}
                                            value={v}
                                            onChange={(value: any) =>
                                              handleChange(i, k, value)
                                            }
                                            err={
                                              dynamicFieldsErrors[i]?.[
                                                "bank_name"
                                              ]
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {k === "account_number" ? (
                                        <div className="pt-2 col-span-2">
                                          <Input
                                            className="me-2 w-full"
                                            name={`bankAcNo-${i}`}
                                            type="number"
                                            placeholder="Enter bank account number"
                                            label="Bank A/C No"
                                            value={v}
                                            onChange={(e) => {
                                              if (
                                                /^\d*$/g.test(e?.target?.value)
                                              ) {
                                                handleChange(
                                                  i,
                                                  k,
                                                  e.target.value
                                                );
                                              }
                                            }}
                                            err={
                                              dynamicFieldsErrors[i]?.[
                                                "account_number"
                                              ]
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {k === "upi_id" ? (
                                        <div className="pt-2 col-span-2">
                                          <Input
                                            className="me-2 w-full"
                                            name={`upiId-${i}`}
                                            placeholder="Enter UPI ID"
                                            label="UPI ID"
                                            value={v}
                                            onChange={(e) =>
                                              handleChange(i, k, e.target.value)
                                            }
                                            err={
                                              dynamicFieldsErrors[i]?.["upi_id"]
                                            }
                                          />
                                        </div>
                                      ) : null}
                                    </Fragment>
                                    {array?.length - 1 === index ||
                                    (array?.length - 1 === index &&
                                      arr?.length - 1 === i) ? (
                                      <div className="flex justify-center  gap-2 pt-8">
                                        {array?.length - 1 === index ? (
                                          <>
                                            <Button
                                              className="!p-1 !border-none !rounded-full !w-9 !h-9"
                                              variant="Transaparent"
                                              onClick={() =>
                                                handleDeleteBank(i)
                                              }
                                            >
                                              <Icon
                                                name="DeleteIcon"
                                                width={20}
                                                height={20}
                                              />
                                            </Button>
                                          </>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </>
                                ) : null;
                              }
                            )}
                          </Fragment>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="flex justify-end items-center gap-2 !pt-4">
                  {isLoading ? (
                    <div className="flex item-center justify-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="flex justify-between w-full items-center">
                      <Button
                        onClick={() => {
                          validateFields();
                          setTimeout(() => {
                            if (
                              dynamicFieldsErrors?.every(
                                (item: any) =>
                                  !!Object.values(item)?.every(
                                    (element: any) => element === ""
                                  )
                              )
                            ) {
                              if (!customValidation()) {
                                return;
                              }
                              handleAddFields();
                            }
                          }, 1000);
                        }}
                        className="!p-2 !border-none  !rounded-md"
                        variant="Transaparent"
                      >
                        <Icon
                          name="PlusIcon"
                          width={16}
                          height={16}
                          className="mr-2"
                        />{" "}
                        Add more
                      </Button>
                      <Button className="!text-sm rounded-lg" type="submit">
                        {data?.id ? "Update" : "Submit"}
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          ) : (
            <>
              <div
                style={{
                  padding: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  <span>Username :- </span>
                  <span className="bold">{isCopy?.user_name}</span>
                </span>
                <span>
                  <span>Password :- </span>
                  <span className="bold">{isCopy?.password}</span>
                </span>
              </div>
              <div className="flex justify-center pt-2 items-center gap-3">
                <CopyToClipboard
                  text={`${isCopy?.user_name} ${isCopy?.password}`}
                >
                  <Button
                    onClick={() => {
                      onClose();
                      setIsCopy(null);
                      showToaster("Copied");
                      reset();
                    }}
                  >
                    Copy & Close
                  </Button>
                </CopyToClipboard>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserModal;
