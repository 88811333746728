// UI IMPORT
import { Button, Icon, Input, Loader, Password } from "../../ui";

// PROJECT IMPORT
import { useLoginMutation } from "./authApi";
import { showToaster } from "../../helperFunctions";
import { LoginFormTypes } from "./types";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const outerLogo: any = process.env.REACT_APP_OUTER_LOGO;

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();

  const schema = yup.object({
    emailId: yup.string().required("Please enter a email Id"),
    password: yup.string().required("Please enter a password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<LoginFormTypes>(schema),
  });

  const onSubmit = async (values: LoginFormTypes) => {
    try {
      const res = await login(values).unwrap();
      showToaster(res?.message);
    } catch (err: any) {
      console.error("Login Error =-=>", err);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <form
        className="grid  h-screen login-wrapper "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="bg-[#fff] flex">
          <div className="login-main rounded-xl bg-chatlook-white w-4/12 md:w-7/12 sm:w-11/12  m-auto ">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-[140px] ">
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    outerLogo
                  )}`}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="welc-text text-center my-6">
              <h4>Welcome Back</h4>
              <p className="mt-1">Login to your account</p>
            </div>
            <div className="space-y-5 pb-10">
              <Input
                className="!w-full"
                inputClassName="!h-12"
                icon="EmailIcon"
                name="emailId"
                placeholder="Email Address"
                label="Username"
                errors={errors}
                register={register}
              />
              <Password
                className="!w-full"
                inputClassName="!h-12"
                name="password"
                placeholder="Password"
                label="Password"
                errors={errors}
                register={register}
              />
            </div>
            <div className="flex items-center justify-center w-full">
              <Button type="submit" className="!px-20">
                Login
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
