import React, { ReactNode, useState } from "react";

interface TooltipProps {
  text?: string;
  children?: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="relative inline-block cursor-pointer"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div className="">{children}</div>
      {isVisible && (
        <div className="capitalize opacity-100 visible bg-black text-white text-xs rounded py-1 px-2 absolute z-10 max-w-sm whitespace-pre-wrap leading-tight bottom-full mb-2">
          {text}
          {/* <svg className="absolute text-black h-2 w-full left-0 transform translate-x-1/2 -translate-y-full" style={{top: "100%"}} viewBox="0 0 255 255" xmlSpace="preserve">
            <polygon className="fill-current" points="127.5,0 255,255 0,255" />
          </svg> */}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
