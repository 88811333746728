// UI IMPORT
import * as iconComponents from "./icons";
import { IconProps } from ".";

const Icon = (props:IconProps) => {
    const { className = "",size, name,alt, ...rest } = props;
    return (
        <img src={iconComponents[name]} className={`${className} cursor-pointer`} width={size} height={size} alt={alt ? alt : name} {...rest} />
    );
};

export default Icon;
