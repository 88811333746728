import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Avatar,
  Button,
  ControlledInput,
  Input,
  Table,
  TableRow,
} from "../../ui";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdBlock, MdDelete } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineClear } from "react-icons/md";
import { PiHandPointingFill } from "react-icons/pi";
import { IoChevronBack } from "react-icons/io5";
import { TbDeviceMobileCog } from "react-icons/tb";

// PROJECT IMPORT

import {
  useAddUpdateNickNameMutation,
  useBlockUserDeviceMutation,
  useDeleteNickUserMutation,
  useGetUserDetailsMutation,
} from "./userApi";
import { useUserDetailsData } from "./userSlice";

// THIRD - PARTY IMPORT
import SendNotificationButton from "./SendNotificationButton";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../helperFunctions";
import { statusOptions } from "./mock";
import BankAsignModal from "./BankAsignModal";
import { useParams, useNavigate, useLocation } from "react-router";
import Tooltip from "../../ui/elements/Tooltip";
import DeleteModal from "../common/DeleteModal";

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const userDetailsData = useUserDetailsData();

  const unUsedBanks = userDetailsData?.unusebank;

  const [getUserDetails, { isLoading }] = useGetUserDetailsMutation();
  const [addUpdateNickName, { isLoading: isAddUpdateNickName }] =
    useAddUpdateNickNameMutation();
  const [blockUserDevice, { isLoading: isblockUserDevice }] =
    useBlockUserDeviceMutation();
  const [deleteNickUser, { isLoading: isUserLoading }] =
    useDeleteNickUserMutation();

  const [asignBankModal, setAsignBankModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [updateNickName, setUpdateNickName] = useState(null);
  const [tableData, setTableData] = useState<any>([]);
  const [currentData, setCurrentData] = useState({});
  const [isOpen, setIsOpen] = useState("");
  const [isOpenConfirmation, setisOpenConfirmation] = useState(false);

  const location = useLocation();

  const nickID = location?.state?.nickID || "";
  // const nickName = location?.state?.nickName || "";

  const { control, getValues, setValue } = useForm<any>({
    defaultValues: {
      // nickName: nickName || "",
    },
  });
  const handleClearState = () => {
    navigate(location.pathname);
  };

  const onBlockUserDevice = async (nickNameId: any, is_block: any) => {
    try {
      const payload: any = {
        nickNameId: nickNameId,
        isBlock: is_block,
      };
      const res = await blockUserDevice(payload).unwrap();
      responseToaster(res);
      onGetUserDetails();
    } catch (err) {}
  };

  const onDeleteNickUser = async () => {
    try {
      const res = await deleteNickUser({ nickNameId: deleteID }).unwrap();
      setisOpenConfirmation(false);
      onGetUserDetails();
      responseToaster(res);
    } catch (err) {}
  };

  const toggleDropdown = (id: string) => {
    setIsOpen(id);
  };

  const columns = [
    {
      title: "#",
      Cell: (data: any) => (
        <>
          <div className="flex gap-2 relative">
            <div onClick={() => toggleDropdown(data?.id)}>
              <BsThreeDotsVertical className="cursor-pointer" size={18} />
            </div>

            {isOpen === data?.id && (
              <div className="absolute top-2">
                <Dropdown
                  isOpen={isOpen === data?.id}
                  toggle={() => toggleDropdown("")}
                >
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent "
                    id="page-header-user-dropdown"
                    tag="button"
                  >
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 5,
                      gap: 2,
                    }}
                  >
                    <div className="gap-2">
                      {data?.device_version ? (
                        <div className="flex items-center gap-2 py-1">
                          <TbDeviceMobileCog /> {data?.device_version}
                        </div>
                      ) : null}
                      <div
                        className="cursor-pointer flex items-center gap-2"
                        onClick={() => {
                          setAsignBankModal(true);
                          setCurrentData(data);
                          toggleDropdown("");
                        }}
                      >
                        <PiHandPointingFill /> Assign Banks
                      </div>
                      <div
                        className="cursor-pointer flex items-center gap-2 py-1"
                        onClick={() => {
                          toggleDropdown("");
                          onBlockUserDevice(
                            data?.id,
                            data?.is_block === 0 ? 1 : 0
                          );
                        }}
                      >
                        <MdBlock />
                        {data?.is_block
                          ? "UnBlock Device ID"
                          : "Block Device ID"}
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Nickname",
      name: "nick_name",
      Cell: (data: any) => {
        return (
          <>
            {updateNickName === data?.id || nickID === data?.id ? (
              <form
                className="flex gap-2"
                onSubmit={() => onAddUpdateNickName(data?.id)}
              >
                <ControlledInput
                  className="!w-36"
                  name="nickName"
                  placeholder="Enter Nickname"
                  control={control}
                />

                <Button type="submit" className="!text-sm w-9 !p-0">
                  <FaCheck />
                </Button>
                <Button
                  variant="Transaparent"
                  className="!text-sm w-9 !p-0"
                  onClick={() => {
                    setUpdateNickName(null);
                    handleClearState();
                  }}
                >
                  <MdOutlineClear />
                </Button>
              </form>
            ) : (
              <span
                className="text-ev-primary cursor-pointer"
                onClick={() => {
                  setUpdateNickName(data?.id);
                  setValue(
                    "nickName",
                    data?.nick_name ? data?.nick_name : "Set Nickname"
                  );
                }}
              >
                {data?.nick_name ? data?.nick_name : "Set Nickname"}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Device ID",
      name: "device_id",
    },
    {
      title: "Bank Details",
      Cell: (data: any) => (
        <>
          <div className="d-flex flex-col">
            {data?.sms_user_bank_details?.length
              ? data?.sms_user_bank_details?.map((item: any) => (
                  <div className="d-flex gap-1 flex-row mb-2">
                    <p>Account: {item?.account_number ?? "-"}</p>
                    <p>UPI: {item?.upi_id ?? "-"}</p>
                    <p>Bank: {item?.bank_name ?? "-"}</p>
                    <p>IFSC: {item?.ifsc_code ?? "-"}</p>
                  </div>
                ))
              : "-"}
          </div>
        </>
      ),
    },
    {
      title: "Sync Ago",
      name: "sync_ago",
    },
    {
      title: "Registration Date",
      name: "created_at_ist",
      sortableKey: "created_at_ist",
      sortable: true,
    },
    {
      title: "Last Update",
      name: "updated_at_ist",
      sortableKey: "updated_at_ist",
      sortable: true,
    },
    {
      title: "Battery",
      name: "battery_per",
      Cell: (data: any) => (
        <>
          <span>{data?.battery_per ? `${data?.battery_per}%` : "-"}</span>
        </>
      ),
    },
    {
      title: "Network strength",
      name: "network_strength",
      Cell: (data: any) => {
        const fillWeek = "#ff4c4c";
        const fillModerate = "#6565ff";
        const fillGood = "#d6b82e";
        const fillExcellent = "#1acf1a";
        const unFillColor = "#bdbdbd";
        const network_strength: any = data?.network_strength;
        return (
          <div className="flex justify-center">
            {data?.network_type === "mobile" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-signal-high"
              >
                <path
                  d="M2 20h.01"
                  stroke={`${
                    network_strength === "Weak"
                      ? fillWeek
                      : network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M7 20v-4"
                  stroke={`${
                    network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M12 20v-8"
                  stroke={`${
                    network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M17 20V8"
                  stroke={`${
                    network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
              </svg>
            ) : data?.network_type === "wifi" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="gray"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path
                  d="M12 20h.01"
                  stroke={`${
                    network_strength === "Weak"
                      ? fillWeek
                      : network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M8.5 16.429a5 5 0 0 1 7 0"
                  stroke={`${
                    network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M5 12.859a10 10 0 0 1 14 0"
                  stroke={`${
                    network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M2 8.82a15 15 0 0 1 20 0"
                  stroke={`${
                    network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-signal-high"
              >
                <path
                  d="M2 20h.01"
                  stroke={`${
                    network_strength === "Weak"
                      ? fillWeek
                      : network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M7 20v-4"
                  stroke={`${
                    network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M12 20v-8"
                  stroke={`${
                    network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
                <path
                  d="M17 20V8"
                  stroke={`${
                    network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                  }`}
                />
              </svg>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      name: "is_active",
      sortableKey: "is_active",
      sortable: true,
      Cell: (data: any) => {
        const status: any = statusOptions.find(
          (item: any) =>
            (data?.is_block === 1 ? "Blocked" : data?.is_active) === item?.value
        );

        const isBlocked = data?.is_block === 1;

        return (
          <>
            <div className="flex items-center gap-2">
              {isBlocked ? (
                <MdBlock className="text-red-500" />
              ) : (
                <span className={`${status?.color}`}></span>
              )}
              <span>{status?.label} </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      Cell: (data: any) => (
        <div className="flex gap-2">
          <Tooltip text="Delete">
            <div
              className="cursor-pointer bg-chatlook-secondary rounded-md p-1"
              onClick={(e) => {
                toggleDropdown("");
                setisOpenConfirmation(true);
                setDeleteID(data?.id);
                e.stopPropagation();
              }}
            >
              <MdDelete size={20} />
            </div>
          </Tooltip>
          <div className="flex">
            {data?.is_active === 0 &&
            data?.is_fcm_token !== 0 &&
            data?.is_block !== 1 ? (
              <SendNotificationButton
                className="h-8"
                classNameJustIcon="cursor-pointer bg-chatlook-secondary rounded-md p-1"
                justIcon
                nickNameId={data?.id ?? ""}
              />
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  const onGetUserDetails = async () => {
    try {
      const payload: any = { userId: id };

      const res = await getUserDetails(payload).unwrap();
    } catch (err) {}
  };

  const onAddUpdateNickName = async (nickNameId: any) => {
    try {
      const values = getValues();
      const payload: any = {
        nickNameId: nickNameId,
        nickName: values?.nickName,
      };

      const res: any = await addUpdateNickName(payload).unwrap();
      if (nickID && res?.status) {
        navigate("/sms_log");
      }
      responseToaster(res);
      setUpdateNickName(null);
      onGetUserDetails();
    } catch (err) {}
  };

  useEffect(() => {
    onGetUserDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableData(
      userDetailsData?.data?.length ? [...userDetailsData?.data] : []
    );
  }, [userDetailsData]);

  return (
    <>
      <div>
        <div className="cardcss flex items-end justify-between w-full">
          <div className="flex items-start justify-between w-full px-1">
            <Button onClick={() => navigate(-1)}>
              <IoChevronBack />
            </Button>
            <div className="flex gap-2 items-center ">
              {userDetailsData?.inActiveUser !== 0 ? (
                <SendNotificationButton
                  className="h-9"
                  userName={"All"}
                  isBulk
                  isNotIcon
                  userId={id}
                />
              ) : null}
              <Avatar
                name={userDetailsData?.userName}
                className="border h-9 w-9 border-ev-primary !font-medium text-ev-primary !rounded-lg"
              />
              <div className="capitalize font-semibold text-ev-secondary">
                {userDetailsData?.userName || ""}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div className="App mt-3">
            <Table
              columns={columns}
              isExpendable={false}
              isLoading={isLoading}
              data={tableData}
              setData={setTableData}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      isExpendable={false}
                      item={item}
                    ></TableRow>
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <BankAsignModal
        unUsedBankData={unUsedBanks}
        data={currentData}
        isOpen={asignBankModal}
        onClose={() => {
          setAsignBankModal(false);
          setCurrentData({});
        }}
        getUserData={onGetUserDetails}
      />
      <DeleteModal
        isLoading={isUserLoading}
        isOpen={!!isOpenConfirmation}
        text="Are you sure you want to delete this user?"
        onHide={() => {
          setisOpenConfirmation(false);
        }}
        onDoneClick={() => {
          onDeleteNickUser();
        }}
      />
    </>
  );
};

export default UserDetails;
