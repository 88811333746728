import { Fragment, useEffect } from "react";

// UI IMPORT
import { Icon } from "../../ui";

// PROJECT IMPORT
import { toggleDrawer, useIsDrawerOpen } from "../common/commonSlice";
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";
import { useMedia } from "../../components";
import SideBarMenu from "./SideBarMenu";

const innerLogo: any = process.env.REACT_APP_INNER_LOGO;

const Sidebar = () => {
  const user = useUser();
  const isDrawerOpen = useIsDrawerOpen();
  const { media } = useMedia();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleDrawer(media.width < 992 ? false : true));
    // eslint-disable-next-line
  }, [media]);

  return (
    <>
      <div className="mainSidebar">
        <div
          className={`sideBar relative whitespace-nowrap ${
            isDrawerOpen ? "w-[220px]" : "w-[75px]"
          } ${media.width < 768 && !isDrawerOpen ? "hidden" : ""}`}
        >
          <div className="sideBarLogo boxCenter">
            {isDrawerOpen ? (
              <div className="flex justify-between">
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    innerLogo
                  )}`}
                  className="w-36"
                  alt="Logo"
                />
                <Icon
                  className="w-6"
                  name="MenuSidebar"
                  onClick={() => {
                    dispatch(toggleDrawer());
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-center">
                <Icon
                  className="w-6"
                  name="MenuSidebar"
                  onClick={() => {
                    dispatch(toggleDrawer());
                  }}
                />
              </div>
            )}
          </div>

          {/* ======= Navigation ======= */}
          <div className="navigation mt-4">
            <nav className="px-2">
              {user?.SiderBar?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <SideBarMenu
                      name={item?.name}
                      route={item?.route}
                      icon_class={item?.icon_class}
                      onClick={() => {
                        if (media.width < 768) {
                          dispatch(toggleDrawer(false));
                        }
                      }}
                    />
                  </Fragment>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
