// INPUT
export const inputTypes = {
  text: "text",
  password: "password",
  number: "number",
  date: "date",
  file:"file"
};

export type InputTypes = keyof typeof inputTypes;

// SELECT
export interface OptionTypes {
  value: string | number;
  label: string | number;
}

// BUTTON
export const buttonTypes = {
  submit: "submit",
  button: "button",
};

export type ButtonTypes = keyof typeof buttonTypes;

export const buttonVariants = {
  Primary: "bg-chatlook-primary text-ev-white",
  Transaparent: "bg-chatlook-gray !border-[1px] !border-chatlook-Quinary !text-ev-Tertiary clear-btn",
  secondary: "bg-[#afadad] text-ev-white",
  Default: "",
};
export type ButtonVariants = keyof typeof buttonVariants;

export const getButtonVariants = (variant: ButtonVariants) =>
  buttonVariants?.[variant] || buttonVariants["Default"];

// STATUS
export const statusTypes = {
  Success: "Success",
  Pending: "Pending",
  Failed: "Failed",
  Processing: "Processing",
  Expired: "Expired",
  Initialized: "Initialized",
  INITIALIZED: "INITIALIZED",
  All: "All",
  "Not Attempted": "Not Attempted",
  Default: "Default",
};

export const statusStyles: typeof statusTypes = {
  Success: "bg-[#15CD2B] text-white",
  Pending: "bg-[#FF9500] text-white",
  Failed: "bg-[#FF4E58F5] text-white",
  Processing: "bg-[#AB78FF] text-white",
  Expired: "bg-[#FFAF65] text-white",
  Initialized: "bg-[#347AE2] text-white",
  INITIALIZED: "bg-[#3B9EF9] text-white",
  All: "bg-[#F584FF] text-white",
  "Not Attempted": "bg-[#FF8E26] text-white",
  Default: "bg-white text-black",
};
export type StatusTypes = keyof typeof statusTypes;

export const getStatusTypes = (type: StatusTypes) =>
  `${statusStyles?.[type]} px-1 py-1 rounded text-xs`;

// TOASTER
export const toasterTypes = {
  Success: "Success",
  Error: "Error",
};

export type ToasterTypes = keyof typeof toasterTypes;
