import { api } from "../../utills/api";

export const bankApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBank: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getBank",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetBankMutation } = bankApi;
export const {
  endpoints: { getBank },
} = bankApi;
