import React, { CSSProperties, PropsWithChildren, useState } from "react";

interface TableCellProps extends PropsWithChildren<{}> {
  className?: string;
  style?: CSSProperties;
  colSpan?: number;
  onClick?: (e?: any) => void;
  type?: string;
}
const TableCell = (props: TableCellProps) => {
  const { className = "", style = {}, type, children, ...rest } = props;
  const isEllipsis = type === "ellipsis";
  return (
    <td
      className={`${className} open-padding table-cell`}
      style={
        isEllipsis
          ? {
            ...style,
            whiteSpace: "normal",
            maxWidth: 330,
            minWidth: 330,
          }
          : {
              ...style,
              whiteSpace: "nowrap",
            }
      }
      {...rest}
    >
      {children}
    </td>
  );
};

export default TableCell;
