import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { userApi } from "./userApi";

interface UserState {
  userData: any;
  userDetailsData: any;
  banks: any;
  merchantList: any;
}

const initialState: UserState = {
  userData: {},
  userDetailsData: {},
  banks: [],
  merchantList: []
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload || {};
      }
    );
    builder.addMatcher(
      userApi.endpoints.getUserDetails.matchFulfilled,
      (state, { payload }) => {
        state.userDetailsData = payload || {};
      }
    );
    builder.addMatcher(
      userApi.endpoints.getBankAndMerchant.matchFulfilled,
      (state, { payload }) => {
        state.banks = payload?.bank || [];
        state.merchantList = payload?.merchant || [];
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default userSlice.reducer;
export const { clearUser } = userSlice.actions;

export const selectUserData = (state: RootState) =>
  state.user.userData;
export const useUserData = () => {
  const depoData = useSelector(selectUserData);
  return useMemo(() => depoData, [depoData]);
};

export const selectUserDetailsData = (state: RootState) =>
  state.user.userDetailsData;
export const useUserDetailsData = () => {
  const depoData = useSelector(selectUserDetailsData);
  return useMemo(() => depoData, [depoData]);
};

export const selectBankData = (state: RootState) =>
  state.user.banks;
export const useBankData = () => {
  const banks = useSelector(selectBankData);
  return useMemo(() => banks, [banks]);
};

export const selectMerchantData = (state: RootState) =>
  state.user.merchantList;
export const useMerchantData = () => {
  const merchantList = useSelector(selectMerchantData);
  return useMemo(() => merchantList, [merchantList]);
};
