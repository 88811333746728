import { api } from "../../utills/api";

export const smsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSMS: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/getSms",
        body: params,
        method: "POST",
      }),
    }),
    AddSMS: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/addSms",
        body: params,
        method: "POST",
      }),
    }),
    getUserList: build.query<{ data: any }, void>({
      query: (params) => ({
        url: "/getUserList",
        body: params,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useGetSMSMutation,
  useAddSMSMutation,
  useLazyGetUserListQuery
} = smsApi;
export const {
  endpoints: { getSMS, AddSMS, getUserList },
} = smsApi;
