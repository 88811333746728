import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { bankApi } from "./bankApi";

interface bankState {
  bankData: any;
}

const initialState: bankState = {
  bankData: {},
};

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    clearUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bankApi.endpoints.getBank.matchFulfilled,
      (state, { payload }) => {
        state.bankData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default bankSlice.reducer;
export const { clearUser } = bankSlice.actions;

export const selectBankData = (state: RootState) => state.bank.bankData;
export const useBankData = () => {
  const banks = useSelector(selectBankData);
  return useMemo(() => banks, [banks]);
};
