import { Route, Routes } from "react-router";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// PROJECT IMPORT
import ProtectedRoute from "./ProtectedRoute";
import { Login } from "../../features";
import UserRoutes from "./UserRoutes";
import { useUser } from "../../features/auth/authSlice";
import { Navigate } from "react-router-dom";

const AllRoutes = () => {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <Routes>
      {!user?.token ? (
        <Route>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      ) : (
        <Route element={<ProtectedRoute />}>
          <Route path="/*" element={<UserRoutes />} />
        </Route>
      )}
    </Routes>
  );
};

export default AllRoutes;
