import { Button, Icon } from "../../ui";
import { useForm } from "react-hook-form";

import { useEffect } from "react";
import DropDown from "../../ui/forms/DropDown";
import { useNickNameAddBankMutation } from "./userApi";
import { responseToaster } from "../../helperFunctions";

const BankAsignModal = (props: any) => {
  const { isOpen, data, onClose, unUsedBankData, getUserData } = props;

  const [nickNameAddBank, { isLoading }] = useNickNameAddBankMutation();

  const {
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    setValue(
      "bank",
      data?.sms_user_bank_details?.map((item: any) => item?.id) ?? []
    );
  }, [data]);

  const prepareBankOptions = () => {
    let options =
      data?.sms_user_bank_details?.map((val: any) => {
        return {
          value: val?.id,
          label: `${val?.bank_name} - ${val?.account_number}`,
        };
      }) || [];
    let options1 =
      unUsedBankData?.map((val: any) => {
        return {
          value: val?.id,
          label: `${val?.bank_name} - ${val?.account_number}`,
        };
      }) || [];
    options = [...options, ...options1];

    return [...(options?.length ? options : [])];
  };

  const addNickName = async () => {
    try {
      const payload: any = {
        nickNameId: data?.id,
        userId: data?.user_id,
        bankId: watch("bank"),
      };

      const res = await nickNameAddBank(payload).unwrap();
      responseToaster(res);
      onClose();
      getUserData();
    } catch (err) {}
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full  bg-gray-900 opacity-50"
        onClick={() => {
          reset();
          onClose();
        }}
      />

      <div className="modal-container bg-white w-4/12 md:w-6/12 sm:w-8/12 xs:w-10/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div
          className="modal-content px-5 pb-5 text-left"
          style={{ maxHeight: "500px" }}
        >
          <div className="flex justify-between items-center sticky top-0 pt-5 pb-2 z-10 bg-chatlook-white">
            <p className="text-xl font-bold text-ev-Tertiary">Bank Assign</p>
            <div className="modal-close cursor-pointer z-50  flex items-center gap-2">
              <Icon
                name="CrossIcon"
                alt="Logo"
                onClick={() => {
                  reset();
                  onClose();
                }}
              />
            </div>
          </div>

          <div>
            <div className="flex gap-2 mb-2">
              <DropDown
                name={`banks`}
                className="w-full"
                label="Banks"
                isMulti
                value={watch("bank")}
                isClearable={true}
                options={prepareBankOptions()}
                noOptionsMessage="No Bank"
                onChange={(e: any, i: any) => {
                  setValue(
                    "bank",
                    e?.map((item: any, index: any) => item)
                  );
                }}
              />
            </div>
            <div className="flex justify-end">
              <Button type="submit" className="!text-sm" onClick={addNickName}>
                Apply
              </Button>
            </div>

            {/* <Input
              className=" w-full"
              name="nick_Name"
              placeholder="Enter Nickname"
              label="Nickname"
              register={register}
              errors={errors}
            />
            <ControlledDropDown
              name="banks"
              className="w-full"
              label="banks"
              isMulti
              isClearable={true}
              options={prepareBankOptions()}
              control={control}
              errors={errors}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAsignModal;
