import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { smsApi } from "./smsApi";

interface SMSState {
  smsData: any;
  userList: any;
}

const initialState: SMSState = {
  smsData: {},
  userList: [],
};

export const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: {
    clearSMS: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      smsApi.endpoints.getSMS.matchFulfilled,
      (state, { payload }) => {
        state.smsData = payload || {};
      }
    );
    builder.addMatcher(
      smsApi.endpoints.getUserList.matchFulfilled,
      (state, { payload }) => {
        state.userList = payload || [];
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default smsSlice.reducer;
export const { clearSMS } = smsSlice.actions;

export const selectSMSData = (state: RootState) => state.sms.smsData;
export const useSMSData = () => {
  const withData = useSelector(selectSMSData);
  return useMemo(() => withData, [withData]);
};

export const selectUserList = (state: RootState) => state.sms.userList;
export const useUserList = () => {
  const userList = useSelector(selectUserList);
  return useMemo(() => userList, [userList]);
};
